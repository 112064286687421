// src/App.js
import React from "react";
import {
  BrowserRouter as Router,
  Routes,
  Route,
  Link,
  Navigate
} from "react-router-dom";
import ChatPage from "./ChatPage"; // Import the original chat page component
import ChatClone from "./ChatClone"; // Import the cloned chat page component

const App = () => {
  return (
    <Router>
      <div className="flex flex-col h-screen">
        {/* Navigation Links */}

        {/* Routes */}
        <Routes>
          <Route path="/" element={<Navigate to="/chat/db" replace />} />
          <Route path="/chat/db" element={<ChatPage />} />
          <Route path="/chat/zoho" element={<ChatClone />} />
        </Routes>
      </div>
    </Router>
  );
};

export default App;
