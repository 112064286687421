// src/ChatPage.js
import React, { useState, useEffect, useRef } from "react";
import parse from "html-react-parser";
import "./App.css"; // Reuse the same CSS for both components
import { Link, useLocation, useParams } from "react-router-dom";

const ChatsUI = ({ api }) => {
  console.log("api", api);
  const location = useLocation();
  const [messages, setMessages] = useState(() => {
    const savedMessages = localStorage.getItem(location?.pathname);
    return savedMessages
      ? JSON.parse(savedMessages)
      : [{ text: "Hello! How can I help you today?", sender: "bot" }];
  });
  const [input, setInput] = useState("");
  const [isLoading, setIsLoading] = useState(false);

  const chatContainerRef = useRef(null);
  const persistMessages = (newMessages) => {
    localStorage.setItem(location?.pathname, JSON.stringify(newMessages));
  };

  const convertToHTML = (message) => {
    const boldedMessage = message.replace(/\*\*(.*?)\*\*/g, "<b>$1</b>");
    return boldedMessage.replace(/\n/g, "<br />");
  };

  const fetchBotResponse = async (userMessage) => {
    setIsLoading(true);

    try {
      const response = await fetch(`${process.env.REACT_APP_API_URL}${api}`, {
        method: "POST",
        headers: {
          "Content-Type": "application/json"
        },
        body: JSON.stringify({ question: userMessage })
      });

      const data = await response.json();

      if (data.status) {
        setMessages((prevMessages) => {
          const newMessages = [
            ...prevMessages,
            { text: convertToHTML(data?.entity || ""), sender: "bot" }
          ];
          persistMessages(newMessages);
          return newMessages;
        });
      } else {
        setMessages((prevMessages) => {
          const newMessages = [
            ...prevMessages,
            {
              text:
                data?.message ||
                "Sorry, something went wrong. Please try again.",
              sender: "bot"
            }
          ];
          persistMessages(newMessages);
          return newMessages;
        });
      }
    } catch (error) {
      setMessages((prevMessages) => {
        const newMessages = [
          ...prevMessages,
          { text: "Error fetching bot response.", sender: "bot" }
        ];
        persistMessages(newMessages);
        return newMessages;
      });
    } finally {
      setIsLoading(false);
    }
  };

  const handleSend = () => {
    if (input.trim()) {
      setMessages((prevMessages) => {
        const newMessages = [...prevMessages, { text: input, sender: "user" }];
        persistMessages(newMessages);
        return newMessages;
      });

      fetchBotResponse(input);
      setInput("");
    }
  };

  const handleKeyPress = (e) => {
    if (e.key === "Enter") {
      e.preventDefault();
      handleSend();
    }
  };

  useEffect(() => {
    if (chatContainerRef.current) {
      chatContainerRef.current.scrollTop =
        chatContainerRef.current.scrollHeight;
    }
  }, [messages]);

  const handleClear = () => {
    setMessages([{ text: "Hello! How can I help you today?", sender: "bot" }]);
    localStorage.removeItem(location?.pathname);
  };

  return (
    <div className="flex flex-col h-screen bg-gray-100">
      {/* <header className="bg-gradient-to-r from-[#192643] to-blue-800 text-white py-4 text-center text-2xl font-bold">
        FAH ASSISTANT
      </header> */}
      <nav className="bg-gray-900 p-4 flex items-center justify-around">
        {/* Left Side Text */}
        <div className="text-white font-bold">FAH Assistant</div>

        {/* Navigation Links */}
        <ul className="flex space-x-4 justify-center">
          <li>
            <Link
              to="/chat/db"
              className={`px-4 py-2 rounded-md focus:outline-none transition duration-300 ${
                location.pathname === "/chat/db"
                  ? "bg-gray-800 text-white"
                  : "text-gray-500 hover:bg-gray-700 hover:text-white"
              }`}
            >
              DB
            </Link>
          </li>
          <li>
            <Link
              to="/chat/zoho"
              className={`px-4 py-2 rounded-md focus:outline-none transition duration-300 ${
                location.pathname === "/chat/zoho"
                  ? "bg-gray-800 text-white"
                  : "text-gray-500 hover:bg-gray-700 hover:text-white"
              }`}
            >
              ZOHO
            </Link>
          </li>
        </ul>
      </nav>

      <div
        ref={chatContainerRef}
        className="flex-1 p-4 overflow-y-auto chat-container"
      >
        <div className="max-w-3xl mx-auto space-y-4">
          {messages.map((msg, index) => (
            <div
              key={index}
              className={`flex ${
                msg.sender === "user" ? "justify-end" : "justify-start"
              }`}
            >
              <div
                className={`px-4 py-2 rounded-2xl shadow-md max-w-xs ${
                  msg.sender === "user"
                    ? "bg-blue-500 text-white"
                    : "bg-gray-200 text-black"
                }`}
              >
                {parse(msg.text)}
              </div>
            </div>
          ))}

          {isLoading && (
            <div className="flex justify-start">
              <div className="px-4 py-2 rounded-lg shadow-md max-w-xs bg-gray-200 text-black">
                <div className="typing-loader">
                  <span></span>
                  <span></span>
                  <span></span>
                </div>
              </div>
            </div>
          )}
        </div>
      </div>

      <div className="bg-white p-4 border-t flex items-center space-x-4 input-container">
        <input
          type="text"
          className="flex-1 px-4 py-2 border rounded-full focus:outline-none focus:ring-2 focus:ring-blue-500"
          placeholder="Type your message..."
          value={input}
          onChange={(e) => setInput(e.target.value)}
          onKeyDown={handleKeyPress}
        />
        <button
          onClick={handleSend}
          className="bg-blue-500 text-white px-4 py-2 rounded-full hover:bg-blue-600 transition"
        >
          Send
        </button>
        <button
          onClick={handleClear}
          className="bg-red-500 text-white px-4 py-2 rounded-full hover:bg-red-600 transition"
        >
          Clear
        </button>
      </div>
    </div>
  );
};

export default ChatsUI;
